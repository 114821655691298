import React, { useState, useEffect } from "react";
import { Input, Card, Form, Button, message, Space, Row, Col } from "antd";
import { apiConfirmOTP, apiRegister, apiGetOtp } from "api/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Password from "antd/es/input/Password";

const ConfirmEmail = () => {
  const key = sessionStorage.k;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isReLoading, setReIsLoading] = useState(false);
  const [count, setCount] = useState(60);
  const [registerKey, setRegisterKey] = useState(sessionStorage.r_key || "");
  const registerObj = sessionStorage.r_info
    ? JSON.parse(sessionStorage.r_info)
    : {};
  // const registerKey = sessionStorage.r_key ? sessionStorage.r_key : "";

  const confirm = async (values) => {
    const { otp } = values;
    setIsLoading(true);
    try {
      const res = await apiConfirmOTP({
        otp,
        key: registerKey,
      });
      const { content } = res.data;
      sessionStorage._skyFilm_t = content;

      setTimeout(() => {
        if (sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0)
          register(registerObj, content);
      }, 5000);
      sessionStorage.removeItem("k");
      sessionStorage.removeItem("r_key");
    } catch (err) {
      message.error("發生錯誤，請確認驗證碼是否正確");
    }
  };

  const register = async (data, tok) => {
    delete data.email2;
    delete data.password2;
    try {
      const res = await apiRegister(data, tok);
      message.success("註冊成功");
      setTimeout(() => {
        navigate("/login?fromRegister=true");
      }, 1200);
    } catch (error) {
      message.error(error.response.data.message, 5);
    } finally {
      sessionStorage.removeItem("r_info");
      sessionStorage.removeItem("_skyFilm_t");
      setIsLoading(false);
    }
  };

  const onFinish = (values) => {
    values.key = key;
    confirm(values);
  };

  // # 重新發送 OTP
  const onResend = async () => {
    setReIsLoading(true);
    //#重新發送的api
    try {
      const res = await apiGetOtp({ email: registerObj?.email });
      setRegisterKey(res?.data?.content);
    } catch (error) {
      message.error(error.message);
    } finally {
      setReIsLoading(false);
    }
    setCount(60);
  };

  useEffect(() => {
    if (count > 0) {
      // # 計時器
      const timer = setInterval(() => {
        setCount(() => {
          return count - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [count]);

  return (
    <div className="md:py-60 mobile:p-4 mobile:mt-10 mobile:mb-40">
      <Row>
        <Col xl={{ span: 8, offset: 8 }}>
          <p className="mb-5 bg-[#4C9BFB] text-white font-semibold p-3 rounded-lg text-base">
            驗證碼已發送到 {registerObj?.email}
            <span className="mobile:block">
              信箱有誤?
              <Button
                type="link"
                onClick={() => navigate("/register")}
                className="text-white underline decoration-2"
              >
                重新填寫
              </Button>
            </span>
          </p>
          <Card
            title={<h4 className="">請填入您收到的OTP密碼</h4>}
            className="mobile:p-4 mx-auto"
            bordered={false}
          >
            <Form onFinish={onFinish} className="[&_.ant-otp-input]:text-2xl">
              <Form.Item name="otp" className="desktop:px-20">
                <Input.OTP
                  className="text-lg"
                  variant="filled"
                  type="number"
                  // formatter={(str) => str.toUpperCase()}
                />
              </Form.Item>
              <Form.Item>
                <Space>
                  <Button
                    onClick={onResend}
                    type="primary"
                    className="bg-black w-full [&.ant-btn:disabled]:text-gray-400 [&.ant-btn:disabled]:bg-gray-300"
                    loading={isReLoading}
                    disabled={count > 0}
                  >
                    重新發送（{count}秒)
                  </Button>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="bg-black w-full"
                    loading={isLoading}
                  >
                    確認
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmEmail;
