import { Button } from "antd";
import { paymentStatus } from "../options";
export const Columns = ({ fetchDetail }) => [
  {
    title: "狀態",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <span
        className={`text-white rounded-md px-2 ${
          text == 4
            ? "bg-green-500"
            : text == 64
            ? "bg-SKF-G-002"
            : "bg-red-500"
        }`}
      >
        {paymentStatus.find((item) => item.value == text)?.label}
      </span>
    ),
  },
  {
    title: "票號",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "價格",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "票券名稱",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "參加者",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    render: (text, record) => (
      <Button
        type="link"
        className="text-left decoration-solid p-0"
        onClick={() => fetchDetail(record)}
      >
        詳情
      </Button>
    ),
  },
];

export const Columns_mobile = ({ fetchDetail }) => [
  {
    title: "狀態 | 票號",
    dataIndex: "id",
    key: "id",
    render: (text, record) => (
      <>
        <span
          className={`text-white rounded-md px-2 ${
            record.status == 4 ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {paymentStatus.find((item) => item.value == record.status)?.label}
        </span>
        <p>{text}</p>
      </>
    ),
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
    render: (text, record) => (
      <Button
        type="link"
        className="text-left decoration-solid p-0"
        onClick={() => fetchDetail(record)}
      >
        詳情
      </Button>
    ),
  },
];
