import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
  Redirect,
} from "react-router-dom";
import App from "App";
import Homepage from "pages/Homepage";
import About from "pages/About";
import Corporation from "pages/Corporation";
import Activity from "pages/Activity";
import CheckOut from "pages/CheckOut";
import Movie from "pages/Movie";
import Register from "pages/Register";
import Member from "pages/member/Member";
import LogIn from "pages/LogIn";
import ResetPsd from "pages/ResetPsd";
import ForgetPsdOtp from "pages/ForgetPsdOtp";
import ConfirmEmail from "pages/ConfirmEmail";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/home",
        element: <Homepage />,
      },
      {
        path: "/corporation",
        element: <Corporation />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/activity/:id",
        element: <Activity />,
      },
      {
        path: "/checkout",
        element: <CheckOut />,
      },
      {
        path: "/confirm-otp",
        element: <ForgetPsdOtp />,
      },
      {
        path: "/confirm-email-otp",
        element: <ConfirmEmail />,
      },
      {
        path: "/reset",
        element: <ResetPsd />,
      },
      // {
      //   path: "/movie",
      //   element: <Movie />,
      // },
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/member",
        element: <Member />,
      },
      {
        path: "/login",
        element: <LogIn />,
      },
      {
        path: "*",
        element: <Navigate to="/home" replace />,
      },
    ],
  },
]);
