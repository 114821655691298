import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  Input,
  Spin,
  message,
  Card,
  Table,
  Modal,
  Tooltip,
  Space,
  Popconfirm,
} from "antd";
import { CaretRightOutlined, QuestionCircleFilled } from "@ant-design/icons";
import {
  apiGetMember,
  apiPatchMember,
  apiResetPsd,
  apiGetOrder,
  apiGetOrderDetail,
  apiGetOtp,
  apiConfirmOTP,
  apiVerifyEmail,
} from "api/api";
import { useNavigate } from "react-router-dom";
import { convertTimeStamp } from "utility/functions/convertTimestamp";

import { Columns, Columns_mobile } from "./columns";
import { paymentStatus, orderStatus } from "./options";

import keyBy from "lodash/keyBy";

const Member = () => {
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const isLogIn =
    sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0;

  const [isLoading, setIsLoading] = useState(false);
  const [isValidEmailLoading, setIsValidEmailLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false); // 是否為手機版&平板，是：訂單中表格改為下拉展開顯示
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [record, setRecord] = useState({});
  const [otpKey, setOtpKey] = useState("");
  const [isOtpLoading, setIsOtpLoading] = useState(false);

  // # 檢視更多
  const [isModalOpen, setIsModalOpen] = useState(false);

  // # 表單是否有變動
  const [isFormChange, setIsFormChange] = useState(false);
  // // # Email輸入欄位是否有變動
  // const [isEmailChange, setIsEmailChange] = useState(false);
  // # 是否顯示OTP輸入欄位
  const [isEmailCodeShow, setIsEmailCodeShow] = useState(false);
  const [formData, setFormData] = useState({});

  // # 取得會員資訊
  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await apiGetMember();
      const { content } = res.data;
      form.setFieldsValue(content);
      setFormData(content);
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1440) {
      setIsMobile(true);
    }
    if (isLogIn) fetchData();
    else navigate("/login");
  }, []);

  // # 取得付款資訊
  const fetchOrder = async () => {
    setIsCardLoading(true);
    try {
      const res = await apiGetOrder();
      const { content } = res.data;
      content.map((item) => {
        item.isActiveCollapse = false;
        item.details.map((dt) => (dt.key = dt.id)); // 為了裡層的 collapse 判斷
      });
      setOrders(content);
    } catch (err) {
      console.log("error", err);
    } finally {
      console.log("oooooo", orders);
      setIsCardLoading(false);
    }
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  // # 取得票券詳情
  const fetchDetail = async (record) => {
    try {
      const res = await apiGetOrderDetail(record.id);
      console.log("rrrr", res);
      const { content } = res.data;
      setRecord(content);
      setIsModalOpen(true);
    } catch (err) {
      console.log("err", err);
    } finally {
    }
  };

  // # 儲存變更
  const updateInfo = async (data) => {
    setIsEditLoading(true);
    try {
      const res = await apiPatchMember(data);
      message.success("儲存成功");
      setIsFormChange(false);
      fetchData();
    } catch (err) {
      message.error("發生問題，儲存失敗");
    } finally {
      setIsEditLoading(false);
    }
  };

  const onFinish = (values) => {
    console.log("Success12:", values);
    updateInfo(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const logOut = () => {
    sessionStorage.removeItem("_skyFilm_t");
    fetchData();
    setTimeout(() => {
      navigate("/home");
      navigate(0);
    }, 500);
  };

  // # 觀察表單是否有變動
  const onValuesChange = (current, all) => {
    setIsFormChange(true);
    // setIsEmailChange(true);
  };

  // # 發送 Email 驗證信
  const handleVerify = (values) => {
    sendOtp(values);
  };

  // # 呼叫發送Email認證信API
  const sendOtp = async (values) => {
    setIsValidEmailLoading(true);
    try {
      const res = await apiGetOtp({ email: form.getFieldValue("email") });
      setOtpKey(res.data.content);
      message.success(`已發送驗證碼至${form.getFieldValue("email")}`);
      setIsEmailCodeShow(true);
    } catch (error) {
      message.error(error.response);
    } finally {
      setIsValidEmailLoading(false);
    }
  };

  // # 驗證Email OTP
  const handleEmailOTP = async (otp) => {
    setIsOtpLoading(true);
    try {
      const res = await apiConfirmOTP({
        otp,
        key: otpKey,
      });
      setTimeout(() => {
        verifyEmail(res.data.content);
      }, 5000);
    } catch (err) {
      message.error("發生錯誤，請確認驗證碼是否正確");
    }
  };

  // # 實際驗證email
  const verifyEmail = async (token) => {
    try {
      const res = await apiVerifyEmail(token);
      setIsEmailCodeShow(false); //#關閉輸入OTP卡片
      message.success("Email認證成功，請使用新信箱重新登入", 4);
      sessionStorage.removeItem("_skyFilm_t");
      setTimeout(() => {
        navigate("/login");
      }, 4500);
    } catch (error) {
      message.error("Email認證發生錯誤，認證失敗");
    } finally {
      setIsOtpLoading(false);
      fetchData();
    }
  };

  // # 重新設定密碼，發送認證碼信件
  const resetPsd = async () => {
    try {
      const res = await apiResetPsd();
      const { content } = res.data;
      message.success("已經發送驗證碼至您的信箱");
      sessionStorage.k = content;
      setTimeout(() => {
        navigate("/confirm-otp");
      }, 1500);
    } catch (err) {
      console.log("err", err);
    }
  };

  // # 關閉彈窗
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // # 選項
  const columns = Columns({ fetchDetail });
  const columns_mobile = Columns_mobile({ fetchDetail });

  return (
    <div className="w-full px-4 desktop:px-56 bg-white py-20">
      <Spin spinning={isLoading} size="large" fullscreen />
      <Row className="mb-20">
        <Col
          sm={{ span: 18 }}
          xs={{ span: 12 }}
          className="text-black text-left"
        >
          <h3 className="m-0">Hello {formData?.name}</h3>
        </Col>
        <Col
          sm={{ span: 6 }}
          xs={{ span: 12 }}
          className="text-black text-right"
        >
          {isLogIn && (
            <Button className="bg-black text-white px-2" onClick={logOut}>
              登出Log out
            </Button>
          )}
        </Col>
      </Row>

      {/* 基本資料 */}
      <Row>
        <Col span={20}>
          <h4 className="m-0 text-left text-black">基本資料 Information</h4>
        </Col>
        {/* <Col span={4} className="text-right">
          <Button className="bg-SKF-G-001 ">Save</Button>
        </Col> */}
      </Row>

      <Form
        name="member"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
        layout="vertical"
        className="relative"
        initialValues={formData}
        onValuesChange={onValuesChange}
      >
        <Row gutter={16}>
          <Col sm={{ span: 24 }} xs={{ span: 24 }} className="mt-6">
            <Form.Item label="Name" name="name">
              <Input placeholder="名字" className="p-2" />
            </Form.Item>
          </Col>
          {/* <Col sm={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item label="Last Name" name="last_name">
              <Input placeholder="姓氏" className="p-2" />
            </Form.Item>
          </Col> */}
          <Col span={24} className="mb-5">
            <Form.Item
              label="Email"
              name="email"
              tooltip="若修改，登入將使用更改後的Email"
              className="mb-1"
            >
              <Input placeholder="Email" className="p-2" />
            </Form.Item>
            {!formData.emailConfirmed && (
              <Button
                type="primary"
                className="float-left mt-2"
                loading={isValidEmailLoading}
                onClick={handleVerify}
              >
                驗證Email
              </Button>
            )}
          </Col>
          {isEmailCodeShow && (
            <Card className="w-full text-left bg-SKF-G-000 rounded-lg mb-5 desktop:[&_.ant-otp]:w-1/4 tablet:[&_.ant-otp]:w-1/2 mobile:[&_.ant-otp]:w-full  shadow-[0px_0px_10px_0px_rgba(0,0,0,0.3)] tablet:[&_.ant-otp-input]:py-3  mobile:[&_.ant-otp-input]:py-3">
              <p className="mb-3 text-base font-semibold">請輸入驗證碼：</p>
              <Card
                loading={isOtpLoading}
                className="[&_.ant-card-body]:p-0 border-none bg-SKF-G-000"
              >
                <Input.OTP
                  variant="filled"
                  type="number"
                  onChange={handleEmailOTP}
                ></Input.OTP>
              </Card>
            </Card>
          )}

          <Col span={24}>
            <Form.Item label="Mobile Phone" name="phone" className="m-0">
              <Input placeholder="電話" className="p-2" />
            </Form.Item>
          </Col>
          <Col span={24} className="text-left absolute top-[-1rem] right-0">
            <Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                loading={isEditLoading}
                disabled={!isFormChange}
              >
                儲存Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className="text-left">
          <Button
            type="link"
            className="p-0 text-SKF-Blue-001"
            onClick={resetPsd}
          >
            重新設定密碼 Reset your password
          </Button>
        </div>
        <div className="mt-8">
          <h4 className="m-0 text-left text-black">消費紀錄 Information</h4>
          <p className="text-SKF-Blue-001 text-base text-left my-2 mb-5">
            <a href="mailto:support@rftexp.com">
              票務問題請聯繫：support@rftexp.com
            </a>
          </p>

          {orders &&
            orders.map((order) => {
              return (
                <Card
                  title={
                    <Space className="text-white w-full [&_.ant-space-item]:grow">
                      <CaretRightOutlined
                        onClick={() => {
                          setOrders(
                            [...orders].map((object) => {
                              if (object.id === order.id) {
                                return {
                                  ...object,
                                  isActiveCollapse: !order.isActiveCollapse,
                                };
                              } else return object;
                            })
                          );
                        }}
                        rotate={order.isActiveCollapse ? 90 : 0}
                        className="mr-5"
                      />
                      <p className="text-center text-balance">{order?.title}</p>
                      <p className="font-semibold text-right">
                        NT＄{order?.price}
                      </p>
                    </Space>
                  }
                  loading={isCardLoading}
                  className="my-2 text-left [&_.ant-card-head]:text-black [&_.ant-card-head]:bg-black mobile:[&_.ant-card-head]:px-4"
                >
                  <Row>
                    <Col span={24} className="text-left">
                      <p className="text-SKF-G-002">
                        訂單狀態：
                        <span className="font-bold">
                          {
                            orderStatus.find(
                              (item) => item.value === order?.status
                            )?.label
                          }
                        </span>
                        {order?.status === 64 && (
                          <Tooltip
                            title="超過一小時未完成付款，自動取消"
                            className="ml-1"
                            color="#9D9D9D"
                          >
                            <QuestionCircleFilled />
                          </Tooltip>
                        )}
                      </p>
                      {order?.status === 32 && (
                        <p className="text-SKF-G-002">
                          支付訊息：
                          <span className="font-bold">
                            {order?.message || "-"}
                          </span>
                        </p>
                      )}
                      <p className="text-SKF-G-002">
                        消費時間：
                        {convertTimeStamp(order?.creationDate)}
                      </p>
                      <p className="text-SKF-G-002">訂單編號：{order?.id}</p>
                      <p className="text-SKF-G-002">
                        付款方式：
                        {order?.paymentMethod === 1 ? "信用卡" : "ATM虛擬帳號"}
                      </p>
                      <p className="text-SKF-G-002">
                        票券數量：
                        {order?.details?.length}
                      </p>
                    </Col>
                  </Row>
                  {order?.isActiveCollapse && (
                    <Row className="mt-5">
                      <Col span={24}>
                        <Card className="[&_.ant-card-body]:p-0">
                          <Table
                            className="w-full"
                            columns={isMobile ? columns_mobile : columns}
                            dataSource={order?.details}
                            pagination={false}
                            expandable={
                              isMobile
                                ? {
                                    expandedRowRender: (record) => (
                                      <>
                                        <p className="m-0">
                                          票券名稱：{record.title}
                                        </p>
                                        <p className="m-0">
                                          價格：{record.price}
                                        </p>
                                        <p className="m-0">
                                          參加者：{record.name}
                                        </p>
                                        <p className="m-0">
                                          Email{record.email}
                                        </p>
                                      </>
                                    ),
                                    rowExpandable: (record) =>
                                      record.name !== "Not Expandable",
                                  }
                                : null
                            }
                          ></Table>
                        </Card>
                      </Col>
                    </Row>
                  )}
                </Card>
              );
            })}
        </div>
      </Form>
      <Modal
        title="票券詳情"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <h6 className="bg-black text-white p-3 rounded-md my-2">
          {record.status !== 4 &&
            paymentStatus.find((item) => item.value === record.status)?.label}
        </h6>
        <p>活動名稱：{record.activityTitle}</p>
        <p>參加人/Name：{record.name}</p>
        <p>票號/Ticket Number：{record.id}</p>
        <p>票價/Price：{record.price}</p>
        <p>座位/Seat：自由選位</p>
        <p>票種/Ticket Type：{record.title}</p>
        <p>
          時間/Time：
          {`${convertTimeStamp(
            record.startDateTime,
            "MM/DD ddd - HH:mm"
          )}（opens at ${convertTimeStamp(
            record.effectiveStartDateTime,
            "HH:mm"
          )}）`}
        </p>
        <p>
          有效時間/Available：
          {convertTimeStamp(record.effectiveStartDateTime, "MM/DD ddd - HH:mm")}
          &nbsp; - &nbsp;
          {convertTimeStamp(record.effectiveEndDateTime, "MM/DD ddd - HH:mm")}
        </p>
        <p>
          活動地點/Location：
          <a href={record.locationUrl}>
            {record.location}
            {record.locationTitle !== "undefined" &&
              `(${record.locationTitle})`}
          </a>
        </p>

        {record?.status === 4 && (
          <>
            <p className="mt-6 mb-3">
              入場QR Code
              <img
                src={`data:image/jpeg;base64,${record.qrCode}`}
                className="w-1/4"
                alt="QRCode"
              />
            </p>
            <p>字軌：{record.taxSerialNumber}</p>
            <p>
              內容代徵娛樂稅 <br></br>
              本娛樂票券經{record.issuer}
              核准使用，並已向演出地之稅捐稽徵申請報繳娛樂稅
            </p>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Member;
