// # 訂單狀態
export const orderStatus = [
  {
    value: 0,
    label: "",
  },
  {
    value: 1,
    label: "訂單失敗",
  },
  {
    value: 2,
    label: "訂單待確認",
  },
  {
    value: 4,
    label: "訂單已付款",
  },
  {
    value: 8,
    label: "整筆訂單退款",
  },
  {
    value: 16,
    label: "部分訂單退款",
  },
  {
    value: 32,
    label: "建立失敗",
  },
  {
    value: 64,
    label: "已被取消",
  },
];

// # 付款狀態
export const paymentStatus = [
  {
    value: 0,
    label: "",
  },
  {
    value: 1,
    label: "訂單未付款",
  },
  {
    value: 2,
    label: "訂單待確認",
  },
  {
    value: 4,
    label: "訂單已付款",
  },
  {
    value: 8,
    label: "整筆訂單退款",
  },
  {
    value: 16,
    label: "部分訂單退款",
  },
  {
    value: 32,
    label: "建立失敗",
  },
  {
    value: 64,
    label: "已被取消",
  },
];
