import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { setIsChartOpen } from "store/slice/index";

import ChartTable from "components/chartTable";
import ScrollToTop from "utility/functions/scrollToTop.js";

import {
  Layout,
  Row,
  Col,
  Button,
  Space,
  Badge,
  Dropdown,
  Divider,
  Drawer,
  message,
} from "antd";
import {
  ShoppingOutlined,
  MenuOutlined,
  ArrowRightOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { apiGetCarts } from "api/api";

import { Outlet, useNavigate, useLocation } from "react-router-dom";

import Logo from "assets/header/Logo.png";
import FB from "assets/footer/FB.png";
import IG from "assets/footer/IG.png";

function App() {
  const { Header, Footer, Content } = Layout;
  const navigate = useNavigate();
  const location = useLocation();

  const [messageApi, contextHolder] = message.useMessage();
  const [cartsAmount, setCartsAmount] = useState(0);
  const [isCartsEmpty, setIsCartsEmpty] = useState(true);

  const info = (msg) => {
    messageApi.info(msg || "提示");
  };
  const success = (msg) => {
    messageApi.success(msg || "成功");
  };
  const warning = (msg) => {
    messageApi.warning(msg || "提醒");
  };
  const error = (msg) => {
    messageApi.error(msg || "錯誤");
  };

  const bodyColor = "#000";
  const frontColor = "#FFF";

  const [count, setCount] = useState(0);

  const open = useSelector((state) => state.charts.isChartOpen);
  // # amount 從購物車元件透過dispatch更新購物車列表長度
  const amount = useSelector((state) => state.charts.cartsAmount);

  // # 取得購物車內容
  const fetchCarts = async () => {
    try {
      const res = await apiGetCarts();
      const { details } = res.data.content;
      setCount(details.length);
      console.log("resss", res);
    } catch (err) {
      console.log("error", err);
    }
  };

  // # 一開始從api取得數量，後面觀察amount透過dispatch即時更新長度
  useEffect(() => {
    // 只有在登入後才獲取 carts 列表
    if (sessionStorage._skyFilm_t?.length > 0) fetchCarts();
  }, [amount]);

  const dispatch = useDispatch();

  const showDrawer = () => {
    if (sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0)
      dispatch(setIsChartOpen(true));
    else navigate("/login");
  };

  const onClose = () => {
    dispatch(setIsChartOpen(false));
  };

  const navigateTo = () => {
    if (sessionStorage._skyFilm_t && sessionStorage._skyFilm_t.length > 0) {
      navigate("/member");
    } else {
      navigate("/login");
    }
  };

  // # 結帳按鈕
  const handleCheckOut = () => {
    dispatch(setIsChartOpen(false));
    setTimeout(() => {
      navigate("/checkout");
    }, 500);
  };

  const classNames = {
    header: "[&_*]:text-white [&_*]:text-xl",
  };

  const items = [
    {
      label: (
        <Button
          type="link"
          className="text-SKF-G-002 hover:text-SKF-G-001 mobile:pl-0"
          onClick={() => navigate("/corporation")}
        >
          跟我們合作 Corporate
        </Button>
      ),
      key: "0",
    },
    {
      label: (
        <Button
          type="link"
          className="text-SKF-G-002 hover:text-SKF-G-001 pr-0 mobile:pl-0"
          onClick={() => navigate("/about")}
        >
          關於我們 About
        </Button>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <Button
          type="link"
          className="text-SKF-G-002 hover:text-SKF-G-001 mobile:pl-0"
          onClick={navigateTo}
        >
          會員中心 User
        </Button>
      ),
      key: "3",
    },
  ];

  useEffect(() => {
    // # 進入跳轉到首頁
    if (location.pathname == "/") navigate("/home");
  }, []);

  useEffect(() => {
    if (cartsAmount > 0) setIsCartsEmpty(false);
    else setIsCartsEmpty(true);
  }, [cartsAmount]);

  return (
    <div className="App">
      {contextHolder}
      <Layout>
        <Header
          style={{
            background: bodyColor,
            color: frontColor,
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
          className="mobile:px-3"
        >
          <Row className="items-center h-full max-w-[1440px] mx-auto">
            {/* 「三」 圖標 - 只顯示在 xs 尺寸, 做成下拉選單  */}
            <Col xs={2} className="md:hidden">
              <Dropdown
                menu={{
                  items,
                }}
                trigger={["click"]}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    <MenuOutlined />
                  </Space>
                </a>
              </Dropdown>
            </Col>

            {/* LOGO */}
            <Col
              sm={{ span: 8 }}
              xs={{ span: 20 }}
              className="mobile:text-center"
            >
              <img
                src={Logo}
                className="mobile:my-0 mobile:mx-auto cursor-pointer w-32"
                onClick={() => navigate("/home")}
              />
            </Col>
            {/* mobile:w-1/2 tablet:w-1/2 desktop:w-1/3 */}
            {/* 中間關於我們 | 與我們合作連結 , 手機版縮到下拉選單中 */}
            <Col sm={8} className="mobile:hidden">
              <Space>
                <>{items[0]?.label}</>
                <>{items[1]?.label}</>
              </Space>
            </Col>

            {/* 購物車圖標 */}
            <Col sm={{ span: 8 }} xs={{ span: 2 }} className="text-right">
              <UserOutlined
                className="text-SKF-G-002 text-lg mr-3 mb-2 mobile:hidden"
                onClick={navigateTo}
              />
              <Badge
                count={count}
                color="#fff"
                style={{
                  color: "#000",
                  fontSize: "10px",
                  borderRadius: "10px",
                }}
                showZero={false}
                size="small"
                offset={[0, 18]}
                onClick={showDrawer}
              >
                <ShoppingOutlined className="text-SKF-G-002 text-xl" />
              </Badge>
            </Col>
          </Row>
        </Header>

        <Content style={{ background: bodyColor, color: frontColor }}>
          <ScrollToTop></ScrollToTop>

          <Outlet context={[info, success, warning, error]} />
        </Content>

        <Footer
          style={{ background: bodyColor, color: frontColor }}
          className="py-8 px-12"
        >
          <Row>
            <Col sm={{ span: 12 }} xs={{ span: 24 }} className="mb-4">
              <img
                src={Logo}
                className="object-contain mobile:mx-auto cursor-pointer w-32"
                onClick={() => {
                  navigate("/home");
                }}
              />
            </Col>
            <Col
              sm={{ span: 12 }}
              xs={{ span: 24 }}
              className="text-right mb-4"
            >
              <Space>
                <>{items[0]?.label}</>
                <span className="text-right">{items[1]?.label}</span>
              </Space>
            </Col>

            <Col sm={{ span: 12 }} xs={{ span: 24 }} className="mb-4">
              <div>
                {/* <Button
                  type="default"
                  className="bg-black text-white mobile:w-10/12 md:w-1/3 md:flex justify-between"
                  
                >
                  電子郵件
                  <ArrowRightOutlined className="ml-15 inline self-center" />
                </Button> */}
                <a
                  href="mailto:support@rftexp.com"
                  className="bg-black border-white border-solid border text-white mobile:w-10/12 md:w-1/3 md:flex justify-between p-2 rounded-lg"
                >
                  電子郵件
                  <ArrowRightOutlined className="ml-15 self-center" />
                </a>
              </div>
            </Col>
            <Col sm={{ span: 12 }} xs={{ span: 24 }} className="md:text-right">
              <Space>
                <Button
                  type="link"
                  className="px-0"
                  onClick={() =>
                    window.open("https://www.facebook.com/Skylinefilm")
                  }
                >
                  <img src={FB} className="object-contain" />
                </Button>
                <Button
                  type="link"
                  className="px-0"
                  onClick={() =>
                    window.open("https://www.instagram.com/skylinefilmtw")
                  }
                >
                  <img src={IG} className="object-contain" />
                </Button>
              </Space>
            </Col>
            <Divider className="border-t-white" />
            <Col sm={{ span: 24 }}>
              <p className="md:text-right text-SKF-G-002">
                COPYRIGHT © Eighty-Four Eighty-Five Ltd. ALL RIGHTS RESERVED
              </p>
            </Col>
          </Row>
        </Footer>
        {/* 購物車 */}
        <Drawer
          title="購物車"
          onClose={onClose}
          open={open}
          className="bg-SKF-G-004"
          classNames={classNames}
          size="large"
        >
          <ChartTable setCartsAmount={setCartsAmount} />
          <Button
            type="primary"
            className={`w-full ${
              isCartsEmpty ? "bg-SKF-G-002" : "bg-SKF-Blue-001"
            }`}
            onClick={handleCheckOut}
            disabled={isCartsEmpty}
          >
            結帳
          </Button>
          <h4 className="text-white">票券說明</h4>
          <p className="text-SKF-G-002">
            1.本活動必須年滿18歲以上才可參與，配合電影分級制度，必要時請配合現場工作人員進行身份查證驗，提醒您記得攜帶身分證件。
            <br></br>
            2.活動座位為全躺椅，入座採先來先到制，我們不提供預先劃位或保留座位。{" "}
            <br></br>
            3.每場電影結束後我們都會進行場地整理，連續購買兩場電影的觀眾可留在原座位上無需重新入場，我們的工作人員會協助您進行報到。
            <br></br>
            4.請注意工作人員有權請觀眾調整座位以確保每位購票者都能入席。
            <br></br>
            5.如遇颱⾵或其他不可抗⼒之⾃然因素導致活動必須取消/延期，我們將會透過Skyline
            Film頁面(本頁面)及Skyline Film
            官方粉絲團進⾏公告。若牽涉退票事宜，我們將會按照相關法令規定，並統⼀透過官方網站辦理退費。
            <br></br>
            6.
            除不可抗⼒之⾃然因素外，票卷⼀旦售出，恕不退款，若無法參加活動，請將票券轉讓其他參加⼈。
            <br></br>
            活動現場請勿吸菸，禁止酒後駕車。
          </p>
        </Drawer>
      </Layout>
    </div>
  );
}

export default App;
