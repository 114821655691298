import React, { useState } from "react";
import { Input, Card, Form, Button, message, Row, Col } from "antd";
import { apiConfirmOTP } from "api/api";
import { useNavigate } from "react-router-dom";

const ForgetPsdOtp = () => {
  const key = sessionStorage.k;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const confirm = async (values) => {
    setIsLoading(true);
    try {
      const res = await apiConfirmOTP(values);
      console.log(res);
      const { content } = res.data;
      sessionStorage.temp_t = content;
      setTimeout(() => {
        navigate("/reset");
      }, 1500);
      sessionStorage.removeItem("k");
    } catch (err) {
      console.log("errr", err);
      message.error("發生錯誤，請確認驗證碼是否正確");
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = (values) => {
    values.key = key;
    confirm(values);
  };
  return (
    <div className="md:py-72 mobile:p-4 mobile:mt-10 mobile:mb-40">
      <Row>
        <Col xl={{ span: 8, offset: 8 }}>
          <Card
            title={<h4 className="">請填入您收到的OTP密碼</h4>}
            className="mobile:p-4 mx-auto"
            bordered={false}
          >
            <Form onFinish={onFinish} className="[&_.ant-otp-input]:text-2xl">
              <Form.Item name="otp" className="desktop:px-20">
                <Input.OTP
                  className="text-lg"
                  variant="filled"
                  type="number"
                  // formatter={(str) => str.toUpperCase()}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  className="bg-black w-full"
                  loading={isLoading}
                >
                  確認
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ForgetPsdOtp;
