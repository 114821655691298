import { message } from "antd";
import { apiSuccessHandler, apiErrorHandler } from "./apiHandler";

import axios from "axios";
const baseurl = `${process.env.REACT_APP_API_URL}`;
let isLogOut = false;
const token = sessionStorage._skyFilm_t;
// ========================== base setting ============================
const createAxios = (baseURL) => {
  const newInstance = axios.create({ baseURL, withCredentials: true });

  newInstance.interceptors.response.use(
    (config) => {
      // # put, post, delete 時，呼叫
      // if (config.config.method !== "get") apiSuccessHandler(config.data);
      isLogOut = false;
      return config;
    },
    (error) => {
      if (error.response && !isLogOut) {
        // if (error.response.status == 401) {
        //   message.error("憑證過期，畫面將轉至登入頁面", [4000]);
        //   setTimeout(() => window.location.assign(`/login`), 2000);
        //   isLogOut = true;
        // }
        if (
          error.response.data.code == 130002 &&
          error.response.status == 403 &&
          error.response.config.method !== "get"
        )
          apiErrorHandler(error.response, "帳號權限不足");
        // if (!localStorage.getItem("persist:root")) {
        //   apiErrorHandler(error.response, "憑證過期，請重新登入");
        //   setTimeout(() => window.location.assign(`/login`), 2200);
        // }
      }

      return Promise.reject(error);
    }
  );

  return newInstance;
};

// 管理員登入
const authRequest = createAxios(`${baseurl}/authentication`);

// 活動
const activityRequest = createAxios(`${baseurl}/activity`);

// 購物車
const cartRequest = createAxios(`${baseurl}/cart`);

// 使用者
const memberRequest = createAxios(`${baseurl}/member`);

// 訂單
const orderRequest = createAxios(`${baseurl}/order`);

// 用戶
const meRequest = createAxios(`${baseurl}/me`);

// 聯絡我們
const contactRequest = createAxios(`${baseurl}/contact`);

// POST 登入
export const apiLogIn = (data) => authRequest.post(`verification`, data);

// POST 登入後重設密碼
export const apiResetPsd = () =>
  authRequest.post(``, null, { headers: { Authorization: `Bearer ${token}` } });

// POST 登入前重設密碼
export const apiGetOtpLogIn = (data) => authRequest.post(``, data);

// PUT 驗證OTP
export const apiConfirmOTP = (data) => authRequest.put(`otp`, data);

// PUT 驗證Email (會員中心)
export const apiVerifyEmail = (p_token) => {
  console.log("p_toke", p_token);
  return memberRequest.put(`email`, null, {
    headers: { Authorization: `Bearer ${p_token}` },
  });
};

// POST 發送OTP
export const apiGetOtp = (data) => authRequest.post(`otp`, data);

// POST 註冊
export const apiRegister = (data, tok) =>
  memberRequest.post(`/register`, data, {
    headers: { Authorization: `Bearer ${tok}` },
  });

// GET 獲取活動列表
export const apiGetActivityList = ({ title, offset, limit }) =>
  activityRequest.get(``, {
    params: { title, offset, limit },
  });

// GET 獲取活動內容
export const apiGetActivityContent = ({ id }) => activityRequest.get(`/${id}`);

// GET 獲取購物車
export const apiGetCarts = () =>
  cartRequest.get(``, { headers: { Authorization: `Bearer ${token}` } });

// PUT 更新購物車
export const apiPutCarts = (data) =>
  cartRequest.put(``, data, { headers: { Authorization: `Bearer ${token}` } });

// GET 獲取使用者
export const apiGetMember = () =>
  memberRequest.get(``, { headers: { Authorization: `Bearer ${token}` } });

// PUT 更新密碼
export const apiPutMember = (data) =>
  memberRequest.put(``, data, {
    headers: { Authorization: `Bearer ${sessionStorage.temp_t}` },
  });

// PATCH 更新使用者
export const apiPatchMember = (data) =>
  memberRequest.patch(``, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

// GET 取得用戶訊息
export const apiGetMe = () =>
  meRequest.get(``, {
    headers: { Authorization: `Bearer ${token}` },
  });

// GET 取得訂單
export const apiGetOrder = () =>
  orderRequest.get(``, {
    headers: { Authorization: `Bearer ${token}` },
  });

// GET 取得訂單
export const apiGetOrderDetail = (id) =>
  orderRequest.get(`detail/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

// POST 更新訂單
export const apiPostOrder = (data) =>
  orderRequest.post(``, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

// POST 建立付款資訊
export const apiPostPayOrder = (data, id) =>
  orderRequest.post(`/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

// POST 重新發送
export const apiPostResend = (data, id) =>
  orderRequest.post(`/confirm/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

// POST 發送票券
export const apiPostSend = (id) =>
  orderRequest.post(`/detail/confirm/${id}`, null, {
    headers: { Authorization: `Bearer ${token}` },
  });

// POST 聯絡我們
export const apiPostContactUs = (data) =>
  contactRequest.post(``, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
